import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
import BookStyles from "../components/may-1/book.module.scss";
import CanvasStyles from "../components/may-1/canvas.module.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Grid systems in graphic design cover | May 1 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-1.png" url="https://cssgrid31.brett.cool" pathname="/may-1" mdxType="Metatags" />
    <div className={CanvasStyles.main}>
  <div className={BookStyles.container}>
    <div className={BookStyles.aspectratio}>
      <div className={BookStyles.cover}>
        <div className={BookStyles.grid}>
          <h2 className={BookStyles.author}>
            <svg viewBox="0 0 156 44">
              <text x="0" y="9">
                Josef Müller-Brockmann
              </text>
            </svg>
          </h2>
          <h1 className={BookStyles.engTitle}>
            <svg viewBox="0 0 326 44">
              <text x="0" y="34">
                Grid systems
              </text>
            </svg>
          </h1>
          <h3 className={BookStyles.engSubtitle}>
            <svg viewBox="0 0 150 44">
              <text x="0" y="9">
                in graphic design
              </text>
            </svg>
          </h3>
          <p className={BookStyles.engDescription}>
            <svg viewBox="0 0 156 44">
              <text x="0" y="9">
                A visual communication manual
              </text>
              <text x="0" y="20">
                for graphic designers,
              </text>
              <text x="0" y="31">
                typographers, and
              </text>
              <text x="0" y="42">
                three dimensional designers
              </text>
            </svg>
          </p>
          <h1 className={BookStyles.gerTitle}>
            <svg viewBox="0 0 326 44">
              <text x="0" y="34">
                Raster systeme
              </text>
            </svg>
          </h1>
          <h3 className={BookStyles.gerSubtitle}>
            <svg viewBox="0 0 150 44">
              <text x="0" y="9">
                für die
              </text>
              <text x="0" y="20">
                visuelle Gestaltung
              </text>
            </svg>
          </h3>
          <p className={BookStyles.gerDescription}>
            <svg viewBox="0 0 156 44">
              <text x="0" y="9">
                Ein Handbuch für
              </text>
              <text x="0" y="20">
                Grafikher, Typographen und
              </text>
              <text x="0" y="31">
                Ausstellungsgestalter
              </text>
            </svg>
          </p>
          <p className={BookStyles.publisher}>
            <svg viewBox="0 0 156 13">
              <text x="0" y="9">
                Niggli
              </text>
            </svg>
          </p>
        </div>
      </div>
    </div>
  </div>
    </div>
    <Footer date={1} next={true} mdxType="Footer">
      <p>{`Josef Müller-Brockmann’s classic, “`}<a parentName="p" {...{
          "href": "https://www.niggli.ch/en/grid-systems-in-graphic-design.html",
          "title": "Grid systems in graphic design — Niggli"
        }}>{`Grid systems in graphic design`}</a>{`” provides essential reading for the designing and rationalisation of grid systems used in print design.`}</p>
      <p>{`Recreating the cover with CSS Grid seemed like a good starting point for this 30 day project.`}</p>
      <p>{`I had to use `}<em parentName="p">{`far too many magic numbers`}</em>{` and hacks than I’d have liked to. Responsive text-fitting was accomplished with `}{`<`}{`svg`}{`>`}{` tags, with width set to 100% (hat tip to `}<a parentName="p" {...{
          "href": "https://css-tricks.com/fitting-text-to-a-container/#article-header-id-7",
          "title": "Fitting Text to a Container — Chris Coyier"
        }}>{`CSSTricks`}</a>{`).`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      